<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Header: Personal Info -->
        <div class="d-flex my-2">
          <feather-icon icon="BookIcon" size="19" />
          <h4 class="mb-0 ml-50">COMP</h4>
        </div>

        <validation-observer ref="simpleRules">
          <!-- Form: Personal Info Form -->
          <b-form class="mt-1">
            <b-row>
              <b-col cols="12">
                <div class="d-flex my-2">
                  <h4 class="mb-0">COMP Details</h4>
                </div>
                <b-card no-body>
                  <b-card-body>
                    <!-- COMP NAME -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col cols="3" class="font-weight-bold">
                        <span>COMP NAME (logged in)</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider #default="{ errors }" name="comp name" rules="required">
                          <b-form-input v-model="compName" type="text" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <!-- COMP NAME -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col cols="3" class="font-weight-bold">
                        <span>COMP NAME (not logged in)</span>
                      </b-col>
                      <b-col cols="9">
                        <b-form-input v-model="guestName" type="text" />
                      </b-col>
                    </b-row>
                    <!-- COMP DESCRIPTIONS -->
                    <!-- <b-row class="mb-1 d-flex align-items-start">
                      <b-col cols="3" class="font-weight-bold">
                        <span>DESCRIPTIONS</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider #default="{ errors }" name="description" rules="required">
                          <b-form-textarea id="textarea" v-model="compDes" rows="3" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row> -->
                    <!-- OPEN DATE/TIME -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col cols="3" class="font-weight-bold">
                        <span>SHOWDOWN OPEN DATE/TIME</span>
                      </b-col>
                      <b-col cols="9">
                        <flat-pickr id="openDate" v-model="openDate" class="form-control" :config="{
                          enableTime: true,
                          dateFormat: 'Y-m-d H:i:s',
                          enableSeconds: true,
                          disable: [{ from: '1970-01-01', to: formatDate(disableDate) }],
                        }" />
                      </b-col>
                    </b-row>
                    <!-- COMPETITION DATE/TIME -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col cols="3" class="font-weight-bold">
                        <span>FIRST LEG START DATE/TIME</span>
                      </b-col>
                      <b-col cols="9">
                        <flat-pickr id="competitionDate" v-model="competitionDate" class="form-control" :config="{
                          enableTime: true,
                          dateFormat: 'Y-m-d H:i:s',
                          enableSeconds: true,
                          disable: [{ from: '1970-01-01', to: formatDate(disableDate) }],
                        }" />
                      </b-col>
                    </b-row>

                    <!-- END DATE/TIME -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col cols="3" class="font-weight-bold">
                        <span>END DATE/TIME</span>
                      </b-col>
                      <b-col cols="9">
                        <flat-pickr id="endDate" v-model="endDate" class="form-control" :config="{
                          enableTime: true,
                          dateFormat: 'Y-m-d H:i:s',
                          enableSeconds: true,
                          disable: [{ from: '1970-01-01', to: formatDate(disableDate) }],
                        }" />
                      </b-col>
                    </b-row>
                    <!--PARTICIPANTS DESCRIPTIONS -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col cols="3" class="font-weight-bold">
                        <span>PARTICIPANTS DESCRIPTIONS</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider #default="{ errors }" name="participants descriptions"
                          rules="required|limitCharacters">
                          <vue-editor v-model="participantsDes" :editor-toolbar="customToolbar" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <!--DESCRIPTION INFO -->
                    <!-- <b-row class="mb-1 d-flex align-items-start">
                      <b-col cols="3" class="font-weight-bold">
                        <span>DESCRIPTION INFO</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider #default="{ errors }" name="descriptions info" rules="required">
                          <b-form-textarea id="textarea-info" v-model="desInfo" rows="3" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row> -->
                    <!--MORE INFO -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col cols="3" class="font-weight-bold">
                        <span>MORE INFO</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider #default="{ errors }" name="more info" rules="required">
                          <b-form-input v-model="moreInfo" type="text" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <!--REMEMBER -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col cols="3" class="font-weight-bold">
                        <span>REMEMBER</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider #default="{ errors }" name="remember" rules="required">
                          <b-form-input v-model="remember" type="text" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <!--SCRATCHINGS -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col cols="3" class="font-weight-bold">
                        <span>SCRATCHINGS</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider #default="{ errors }" name="scratchings" rules="required">
                          <b-form-input v-model="scratchings" type="text" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <!--CHALLENGE PLAY RULES -->
                    <!-- <b-row class="mb-1 d-flex align-items-start">
                      <b-col cols="3" class="font-weight-bold">
                        <span>CHALLENGE PLAY RULES</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider #default="{ errors }" name="challenge play rules" rules="required">
                          <vue-editor v-model="challengePlayRules" :editor-toolbar="customToolbar" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row> -->

                    <!-- ENTRY FEE -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col cols="3" class="font-weight-bold">
                        <span>ENTRY FEE</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider #default="{ errors }" name="price to" rules="required">
                          <b-form-input v-model="priceTo" min="0" type="number" :no-wheel="true" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <!-- RE BUY FEE -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col cols="3" class="font-weight-bold">
                        <span>RE BUY FEE</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider #default="{ errors }" name="late change price" rules="required">
                          <b-form-input v-model="latePrice" min="0" type="number" :no-wheel="true" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>

                    <!-- LIMIT REAL BETSLIP -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col cols="3" class="font-weight-bold">
                        <span>LIMIT BETSLIP</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider #default="{ errors }" name="limit betslip" rules="required">
                          <b-form-input id="limit-value" v-model="limitBetslip" :formatter="formatNumberBetslip" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>

                    <!-- Manual Betslip entry -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col cols="3" class="font-weight-bold">
                        <span>MANUAL BETSLIP ENTRY (optional)</span>
                      </b-col>
                      <b-col cols="9">
                        <b-form-input id="limit-value" v-model="manualBetSlipEntry" :formatter="formatNumberBetslip" />
                      </b-col>
                    </b-row>
                    <!-- Api Price -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col cols="3" class="font-weight-bold">
                        <span>BONUS ADDITIONAL TICKET (optional)</span>
                      </b-col>
                      <b-col cols="9">
                        <b-form-input v-model="apiPrice" min="0" type="number"
                          placeholder="Enter 100 for 0.5 tickets @ $50" :no-wheel="true" />
                      </b-col>
                    </b-row>

                    <!-- FLEXI -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col cols="12" class="font-weight-bold d-flex justify-content-between mb-1">
                        <span>FLEXI OPTION</span>
                        <b-button variant="primary" size="md" @click="handleAddMoreOptionFlexi"> + Add More </b-button>
                      </b-col>
                      <b-col cols="12">
                        <b-row>
                          <b-col cols="12" md="4" v-for="(item, index) in optionsFlexi" :key="index" class="mb-1">
                            <div class="d-flex justify-content-between mb-1">
                              <span class="d-block"> {{ toCardinal(index + 1) }} Option (%)</span>
                              <b-button variant="danger" size="sm" @click="handleRemoveOptionFlexi"> x </b-button>
                            </div>
                            <validation-provider #default="{ errors }" :name="toCardinal(index + 1) + ' Option'"
                              rules="required">
                              <b-form-input v-model="optionsFlexi[index]" :formatter="formatNumberFlexi" />
                              <small class="text-danger">{{ errors[0] }}</small>
                              <!-- </label> -->
                            </validation-provider>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>

                    <!-- Get Prize From -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col cols="3" class="font-weight-bold">
                        <span>Get Prize From </span>
                      </b-col>
                      <b-col cols="9">
                        <b-form-radio-group id="get-prize" v-model="selectedPrizeFrom" :options="optionsPrizeFrom"
                          name="radio-inline"></b-form-radio-group>
                      </b-col>
                    </b-row>

                    <!-- Is Show Front/Back-->
                    <b-row v-if="selectedPrizeFrom === 'manual'" class="mb-1 d-flex align-items-start">
                      <b-col cols="3" class="font-weight-bold">
                        <span>Front/Back</span>
                      </b-col>
                      <b-col cols="9">
                        <b-form-radio-group id="front-back" v-model="isShowFrontBack" :options="optionsShowFrontBack"
                          name="front-back"></b-form-radio-group>
                      </b-col>
                    </b-row>



                    <!-- Front-Prize -->
                    <b-row v-if="selectedPrizeFrom === 'manual' && isShowFrontBack"
                      class="mb-1 d-flex align-items-start">
                      <b-col cols="3" class="font-weight-bold">
                        <span>Front Prize</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider #default="{ errors }" name="front prize" rules="required">
                          <b-form-input @click="handleShowModalAddFrontPrizes" :value="frontPrizeValue" readonly />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>

                    <!-- Back-Prize -->
                    <b-row v-if="selectedPrizeFrom === 'manual' && isShowFrontBack"
                      class="mb-1 d-flex align-items-start">
                      <b-col cols="3" class="font-weight-bold">
                        <span>Back Prize</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider #default="{ errors }" name="back prize" rules="required">
                          <b-form-input @click="handleShowModalAddBackPrizes" :value="backPrizeValue" readonly />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>

                    <!-- Main-Prize -->
                    <b-row v-if="selectedPrizeFrom === 'manual'" class="mb-1 d-flex align-items-start">
                      <b-col cols="12" class="font-weight-bold d-flex mb-1 align-items-center">
                        <span class="mr-2">Main Prize</span>
                        <div>
                          <!-- <b-button variant="primary" size="md" @click="handleAddMoreInputMainPrize">
                            + Add More Input Prize
                          </b-button> -->

                          <input ref="fileInput" type="file" accept=".csv" hidden @change="handleFileUpload">
                          <b-button v-if="mainPrizes.length === 0" variant="danger" size="md" class="ml-2"
                            @click="onClickInput">
                            Import File
                          </b-button>
                          <b-button v-if="mainPrizes.length !== 0" variant="danger" size="md" class="ml-2"
                            @click="handleShowReviewList">
                            View List
                          </b-button>
                          <b-button v-if="mainPrizes.length !== 0" variant="danger" size="md" class="ml-2"
                            @click="handleRemoveAllPrize">
                            Remove All
                          </b-button>

                        </div>
                      </b-col>
                      
                    </b-row>
                    <!-- BANNER WEB -->
                    <!-- <b-row>
                      <b-media no-body class="d-flex flex-column">
                        <b-media-body class="mt-75 d-flex align-items-center mb-75 ml-75">
                          <span class="font-weight-bold mr-75">UPLOAD BANNER WEBSITE</span>
                          <b-button E variant="primary" size="sm" class="mr-75" @click="$refs.refInputEl.$el.click()">
                            Upload
                          </b-button>
                          <b-form-file ref="refInputEl" accept=".jpg, .png, .gif, .heic, .jpeg" :hidden="true" plain
                            @change="inputImageRenderer" />

                          <b-button variant="outline-secondary" size="sm" class="mr-75" @click="removeImageRenderer">
                            Reset
                          </b-button>
                        </b-media-body>
                        <b-media-aside>
                          <b-img class="banner-img ml-75" v-if="webImage" :src="webImage" alt="logo" />
                        </b-media-aside>
                      </b-media>
                    </b-row> -->
                    <!-- <small v-if="errorBannerWeb" class="text-danger mb-75">Please upload banner image</small> -->
                    <!-- BANNER MOBILE -->
                    <!-- <b-row>
                      <b-media no-body class="d-flex flex-column">
                        <b-media-body class="mt-75 d-flex align-items-center mb-75 ml-75">
                          <span class="font-weight-bold mr-75">UPLOAD BANNER MOBILE</span>
                          <b-button variant="primary" size="sm" class="mr-75"
                            @click="$refs.refInputMobileEl.$el.click()">
                            Upload
                          </b-button>
                          <b-form-file ref="refInputMobileEl" accept=".jpg, .png, .gif, .heic, .jpeg" :hidden="true"
                            plain @change="inputMobileImageRenderer" />

                          <b-button variant="outline-secondary" size="sm" class="mr-75"
                            @click="removeMobileImageRenderer">
                            Reset
                          </b-button>
                        </b-media-body>
                        <b-media-aside>
                          <b-img class="banner-img ml-75" v-if="mobileImage" :src="mobileImage" alt="logo" />
                        </b-media-aside>
                      </b-media>
                    </b-row>
                    <small v-if="errorBannerMobile" class="text-danger mb-75">Please upload banner image</small> -->

                    <!-- FLEXI ROLL OVER POOL -->
                    <b-row class="mb-1 mt-2 d-flex align-items-start">
                      <b-col cols="3" class="font-weight-bold">
                        <h4 style="margin-top: 10px">Use Flexi Roll Over Pool</h4>
                      </b-col>
                      <b-col cols="9" style="display: flex; align-items: center; margin-top: 10px">
                        <b-form-radio-group id="radio-inline-flexi" v-model="selectedFlexiRollOverPool"
                          :options="optionsFlexiRollOverPool" name="radio-inline-flexi"></b-form-radio-group>
                      </b-col>
                    </b-row>



                    <b-row>
                      <template v-if="selectedFlexiRollOverPool">


                        <!-- <b-col cols="12">
                          <small v-if="errorAPIPrice" class="text-danger ml-75">Ticket price is required</small>
                        </b-col> -->

                        <b-col cols="12">
                          <div style="display: flex; align-items: center">
                            <span
                              style="margin-right: 20px; margin-left: 10px; font-weight: bold; min-width: 160px">Roll
                              Over Amount($)</span>
                            <b-form-input v-model="flexiRollOverAmount" min="0" type="number" :no-wheel="true"
                              style="max-width: 100px" :formatter="formatPriceRollOver" />
                            <small style="margin-right: 20px; margin-left: 10px; font-weight: bold">Max: {{
                              formatCurrencyBuiltInFunc(flexiRollOverWallet) }}</small>
                          </div>
                        </b-col>
                        <b-col cols="12">
                          <small v-if="errorFlexiRollOverAmount" class="text-danger mb-75 ml-75">Roll Over Amount is
                            required</small>
                        </b-col>
                      </template>
                    </b-row>

                    <!-- PRIZE TYPE -->
                    <b-row class=" d-flex align-items-start">
                      <b-col cols="3" class="font-weight-bold">
                        <h4>Transfer Prize By </h4>
                      </b-col>
                      <b-col cols="9">
                        <b-form-radio-group id="transfer-prize" v-model="selectedTransferPrize"
                          :options="optionsTransferPrizeBy" name="transfer-prize"></b-form-radio-group>
                      </b-col>
                    </b-row>

                    <!-- JACKPOT -->
                    <b-row class="mb-1 mt-2 d-flex align-items-start">
                      <b-col cols="3" class="font-weight-bold">
                        <h4>Enable Jackpot</h4>
                      </b-col>
                      <b-col cols="9">
                        <b-form-radio-group id="jackpot" v-model="selectedJackpot" :options="optionsJackpot"
                          name="jackpot" />
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>

            <div class="d-flex my-2">
              <h4 class="mb-0">COMP LEGS</h4>
            </div>

            <b-row>
              <b-col cols="12" md="6">
                <h6 class="mb-1">MORNING LOCKOUT</h6>
                <flat-pickr id="morningLockout" v-model="morningLockout" class="form-control mb-1" :config="{
                  enableTime: true,
                  dateFormat: 'Y-m-d G:i:S K',
                  time_24hr: false,
                  enableSeconds: true,
                  static: true,
                  disable: [{ from: '1970-01-01', to: formatDate(disableDate) }],
                }" />
                <ChooseLeg v-for="(item, ind) in frontEntryList" :id="`${ind}-front`" :key="ind" :title="item.title"
                  :value="item.value" type="front" :leg-number="ind + 1" @update-data="handleUpdateLegFront"
                  @delete-data="handleDeleteLegFront" @create-leg="handleCreateLeg" @remove-leg="handleRemoveLeg" />
                <div v-if="frontEntryList.length < 6" class="d-flex justify-content-center">
                  <b-button variant="primary" :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    @click="addLegFront">
                    <template> New leg </template>
                  </b-button>
                </div>
                <!-- <small v-if="errorFrontLeg" class="text-danger">Please add front entries</small> -->
              </b-col>
              <b-col cols="12" md="6">
                <h6 class="mb-1">AFTERNOON LOCKOUT</h6>
                <flat-pickr id="afternoonLockout" v-model="afternoonLockout" class="form-control mb-1" :config="{
                  enableTime: true,
                  dateFormat: 'Y-m-d G:i:S K',
                  enableSeconds: true,
                  static: true,
                  disable: [{ from: '1970-01-01', to: formatDate(disableDate) }],
                }" />
                <ChooseLeg v-for="(item, ind) in backEntryList" :id="`${ind}-back`" :key="ind" :title="item.title"
                  :value="item.value" type="back" :leg-number="ind + 1" @update-data="handleUpdateLegBack"
                  @delete-data="handleDeleteLegBack" @create-leg="handleCreateLeg" @remove-leg="handleRemoveLeg" />
                <div v-if="backEntryList.length < 6" class="d-flex justify-content-center">
                  <b-button variant="primary" :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    @click="addLegBack">
                    <template> New leg </template>
                  </b-button>
                </div>
                <!-- <small v-if="errorBackLeg" class="text-danger">Please add back entries</small> -->
              </b-col>
            </b-row>
            <!-- </b-row> -->

            <b-row class="mt-2">
              <b-col class="d-flex justify-content-end mr-4 mt-4">
                <b-button variant="primary" class="mb-1 mb-sm-0"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'" :disabled="loading"
                  @click="handleCreateComp">
                  <Spinner v-if="loading" />
                  <template v-else> Create comp </template>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
      <!-- MODAL ADD PRIZES -->
      <AddPrizeModal ref="pickPrize" type-pick="front" :data="frontPrizes"
        @on-update-front-prize="handleUpdateFrontPrizes" :isEdit="true" />
      <AddPrizeModal ref="pickPrizeBack" type-pick="back" :data="backPrizes"
        @on-update-back-prize="handleUpdateBackPrizes" :isEdit="true" />

      <!-- MODAL REVIEW LIST PRIZES -->
      <b-modal ref="list-review" hide-footer title="Review list" centered cancel-variant="outline-secondary" size="lg">
        <b-col cols="12">
          <b-row>
            <b-col cols="12" md="3" v-for="(item, index) in mainPrizes" :key="index" class="mb-1">
              <!-- <label for="" > -->

              <div class="d-flex justify-content-between mb-1">
                <span class="d-block"  style="color: black;"> {{ toCardinal(index + 1) }} Prize</span>
                <b-button variant="danger" size="sm" v-if="index === mainPrizes.length -1" @click="handleRemoveMoreInputMainPrize">
                  x
                </b-button>
              </div>
              <validation-provider #default="{ errors }" :name="toCardinal(index + 1) + ' prize'" rules="required">
                <b-form-input v-model="mainPrizes[index]" :formatter="formatNumber" />
                <small class="text-danger">{{ errors[0] }}</small>
                <!-- </label> -->
              </validation-provider>
            </b-col>
          </b-row>
        </b-col>


      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  VBModal,
  BCardBody,
  BFormInput,
  BButton,
  BFormTextarea,
  BFormTags,
  BInputGroup,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BFormFile,
  BFormRadioGroup,
  BFormGroup,
} from 'bootstrap-vue'
import useToastHandler from '@/services/toastHandler'
import vSelect from 'vue-select'
import { required, integer, limitCharacters } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import flatPickr from 'vue-flatpickr-component'
import {
  formatISO,
  formatDate,
  convertTZ,
  getObjectChange,
  formatDateUTC,
  formatDateSend,
  formatDateTimeLocal,
  formatCurrencyBuiltInFunc,
} from '@/helpers/utils'
import ChooseLeg from './ChooseLeg.vue'
import { VueEditor } from 'vue2-editor'
import AddPrizeModal from './AddPrizeModal.vue'
import { formatDateTimeAustralia, toCardinal } from '@/helpers/utils'
import { mapActions, mapState } from 'vuex'
import Spinner from '@core/components/Spinner'
import { subDays } from 'date-fns'
// import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { parse } from 'csv-parse'

export default {
  components: {
    AddPrizeModal,
    ChooseLeg,
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    VBModal,
    BCardBody,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    vSelect,
    flatPickr,
    VueEditor,
    BFormTags,
    Spinner,
    BInputGroup,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BFormFile,
    BFormRadioGroup,
    BFormGroup,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      toCardinal,
      formatDateTimeAustralia,
      errorFrontLeg: false,
      errorBackLeg: false,
      // errorPrizes: false,
      // errorBannerWeb: false,
      // errorBannerMobile: false,
      frontEntryList: [
        { legId: 1, title: 'LEG 1', value: '' },
        { legId: 2, title: 'LEG 2', value: '' },
        { legId: 3, title: 'LEG 3', value: '' },
        { legId: 4, title: 'LEG 4', value: '' },
        { legId: 5, title: 'LEG 5', value: '' },
        { legId: 6, title: 'LEG 6', value: '' },
        { legId: 7, title: 'LEG 7', value: '' },
      ],
      backEntryList: [
        { legId: 1, title: 'LEG 1', value: '' },
        { legId: 2, title: 'LEG 2', value: '' },
        { legId: 3, title: 'LEG 3', value: '' },
        { legId: 4, title: 'LEG 4', value: '' },
        { legId: 5, title: 'LEG 5', value: '' },
        { legId: 6, title: 'LEG 6', value: '' },
        { legId: 7, title: 'LEG 7', value: '' },
      ],
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ color: [] }],
      ],
      frontPrizes: [],
      backPrizes: [],
      mainPrizes: [],
      compName: '',
      percentWinner: '',
      openDate: new Date(),
      competitionDate: new Date(),
      endDate: new Date(),
      morningLockout: new Date(),
      afternoonLockout: new Date(),
      participantsDes: `<p>Showdown Lockout is at 12:49 pm NSW Time on Thursday 30th May 2024</p><p>Rebuy Opens at 12:49 pm NSW Time on Thursday 30th May 2024</p><p>Rebuy Closes at 1:59 pm NSW Time on Thursday 30th May 2024</p><p>--------------------------------------------------</p><p>For this week’s XXX Showdown, we have selected 12 legs across YYY, ZZZ &amp; AAA</p><p>A $50 bet gets you into the Showdown – a 12 leg racecard where you earn points for your horse winning or placing, with points earnt based on the top tote dividends for both win and place. Follow the leaderboard and the players with the most points at the end of the day share the massive prizes</p><p>Someone wins BIG every week, as there are NO jackpots</p><p>Check out the How To Play video for an easy way to learn</p><p>For full T&amp;C’s &amp; Betting Rules click on the tabs within the app or the website</p>`,
      moreInfo:
        'https://upcprize-prod.s3.ap-southeast-2.amazonaws.com/Legal/Showdown+Rules+Published+Version+5+September+2024.pdf',
      remember: 'We pay best tote odds based on official dividends. Dead heat rules apply.',
      scratchings:
        'You will be automatically allocated the lowest numbered runner. Eg runner 1 is the lowest numbered non scratched runner.',

      priceTo: 50,
      latePrice: 15,
      disableDate: subDays(new Date(), 1),

      webImage: null,
      fileImage: null,
      mobileImage: null,
      fileMobileImage: null,

      selectedPrizeFrom: 'api',
      optionsPrizeFrom: [
        { text: 'API', value: 'api' },
        { text: 'Manual', value: 'manual' },
      ],
      optionsShowFrontBack: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      isShowFrontBack: false,

      selectedTransferPrize: 'Cash',
      optionsTransferPrizeBy: [
        { text: 'Cash', value: 'Cash' },
        { text: 'Bonus-Cash', value: 'Bonus-Cash' },
      ],

      optionsFlexi: [],

      optionsFlexiRollOverPool: [
        { text: 'No', value: false },
        { text: 'Yes', value: true },
      ],
      selectedFlexiRollOverPool: false,
      apiPrice: null,
      // errorAPIPrice: false,
      flexiRollOverAmount: null,
      errorFlexiRollOverAmount: false,

      limitBetslip: null,
      manualBetSlipEntry: null,
      guestName: null,

      optionsJackpot: [
        { text: 'No', value: false },
        { text: 'Yes', value: true },
      ],
      selectedJackpot: false,
    }
  },

  computed: {
    ...mapState('races/createRace', ['loading', 'dataPrizes', 'imageWebUrl', 'imageMobileUrl']),
    ...mapState('races/listRace', ['listEventPicked']),
    ...mapState('races/flexiRollOver', ['flexiRollOverWallet']),
    frontPrizeValue() {
      if (!this.frontPrizes) return ''
      return this.frontPrizes.join(', ')
    },
    backPrizeValue() {
      if (!this.backPrizes) return ''
      return this.backPrizes.join(', ')
    },
  },
  watch: {
    frontEntryList(val) {
      if (val.find(item => item.value)) this.errorFrontLeg = false
    },
    backEntryList(val) {
      if (val.find(item => item.value)) this.errorBackLeg = false
    },
    // webImage(val) {
    //   if (val) this.errorBannerWeb = false
    // },
    // mobileImage(val) {
    //   if (val) this.errorBannerMobile = false
    // },
    // apiPrice(val) {
    //   if (val && this.selectedFlexiRollOverPool) this.errorAPIPrice = false
    // },
    flexiRollOverAmount(val) {
      if (val && this.selectedFlexiRollOverPool) this.errorFlexiRollOverAmount = false
    },
    selectedFlexiRollOverPool(val) {
      if (!val) {
        // this.errorAPIPrice = false
        this.errorFlexiRollOverAmount = false
      }
    },
  },
  async mounted() {
    this.setPickListEvent([...this.frontEntryList, ...this.backEntryList])
    this.resetListPrizes()
    await this.getFlexiRollOverWallet()
    // this.mainPrizes = ['', '', '']
  },

  methods: {
    ...mapActions({
      createCompAsync: 'races/createRace/createCompAsync',
      uploadImage: 'races/createRace/uploadImage',
      uploadImageMobile: 'races/createRace/uploadImageMobile',
      setPickListEvent: 'races/listRace/setPickListEvent',
      setPickFrontBack: 'races/listRace/setPickFrontBack',
      resetListPrizes: 'races/createRace/reset',
      getFlexiRollOverWallet: 'races/flexiRollOver/getFlexiRollOverWallet',
    }),
    handleAddMoreInputMainPrize() {
      this.mainPrizes.push('')
    },
    handleRemoveMoreInputMainPrize() {
      this.mainPrizes.pop()
    },
    handleShowModalAddFrontPrizes() {
      this.$refs['pickPrize'].openModal()
    },
    handleShowModalAddBackPrizes() {
      this.$refs['pickPrizeBack'].openModal()
    },

    addLegFront() {
      const length = this.frontEntryList.length
      const item = { legId: length + 1, title: `LEG ${length + 1}`, value: '' }
      this.frontEntryList = [...this.frontEntryList, item]
    },
    addLegBack() {
      const length = this.backEntryList.length
      const item = { legId: length + 1, title: `LEG ${length + 1}`, value: '' }
      this.backEntryList = [...this.backEntryList, item]
    },
    handleUpdateLegFront(data) {
      this.setPickFrontBack(null)
      if (data.type === 'add') {
        this.frontEntryList = this.frontEntryList.map(item => {
          if (data.legNumber === item.legId)
            return {
              ...item,
              value: `${data.raceName} R${data.event.number}, ${formatDateTimeLocal(data.event.startTime)}`,
              ...data.event,
              meetingName: data.raceName,
              meetingId: data.meetingId,
              entryType: 0,
            }
          return item
        })
      }
      if (data.type === 'remove') {
        this.frontEntryList = this.frontEntryList.map(item => {
          if (data.name === item.value) return { ...item, value: '' }
          return item
        })
      }
    },
    handleUpdateLegBack(data) {
      if (data.type === 'add') {
        this.backEntryList = this.backEntryList.map(item => {
          if (data.legNumber === item.legId)
            return {
              ...item,
              value: `${data.raceName} R${data.event.number}, ${formatDateTimeLocal(data.event.startTime)}`,
              ...data.event,
              meetingName: data.raceName,
              meetingId: data.meetingId,
              entryType: 1,
            }
          return item
        })
      }
      if (data.type === 'remove') {
        this.backEntryList = this.backEntryList.map(item => {
          if (data.name === item.value) return { ...item, value: '' }
          return item
        })
      }
    },
    handleDeleteLegFront(data) {
      const deleteItem = this.frontEntryList.find(item => item.legId === data.legNumber)
      if (deleteItem.value) {
        const newListPicked = this.listEventPicked.filter(item => item.raceName !== deleteItem.value)
        this.setPickListEvent(newListPicked)
      }

      const updatedData = this.frontEntryList.filter(item => item.legId !== data.legNumber)

      const newData = updatedData.map((item, index) => ({
        ...item,
        legId: index + 1,
        title: `LEG ${index + 1}`,
      }))
      this.frontEntryList = newData
    },
    handleDeleteLegBack(data) {
      const deleteItem = this.backEntryList.find(item => item.legId === data.legNumber)
      if (deleteItem.value) {
        const newListPicked = this.listEventPicked.filter(item => item.raceName !== deleteItem.value)
        this.setPickListEvent(newListPicked)
      }

      const updatedData = this.backEntryList.filter(item => item.legId !== data.legNumber)

      const newData = updatedData.map((item, index) => ({
        ...item,
        legId: index + 1,
        title: `LEG ${index + 1}`,
      }))
      this.backEntryList = newData
    },
    async handleCreateComp() {
      const valid = await this.$refs.simpleRules.validate()
      if (!valid) return

      const listFrontLeg = this.frontEntryList.filter(item => item.value)

      const listBackLeg = this.backEntryList.filter(item => item.value)

      const arr = [...listFrontLeg, ...listBackLeg]
      // if (!this.webImage) {
      //   this.errorBannerWeb = true
      // }
      // if (!this.mobileImage) {
      //   this.errorBannerMobile = true
      // }
      if (this.selectedFlexiRollOverPool && !this.flexiRollOverAmount) this.errorFlexiRollOverAmount = true
      if (this.errorFlexiRollOverAmount) return

      const entries = arr.map(item => {
        return {
          raceId: item.id,
          raceName: item.name,
          startTime: item.startTime,
          entryType: item.entryType,
          raceNumber: item.number,
          meetingName: item.meetingName,
          meetingId: item.meetingId,
          orderNumber: item.type,
        }
      })
      const formattedFrontPrize = this.frontPrizes.map((item, index) => ({
        order: index + 1,
        value: +item,
        type: 'front',
      }))
      const formattedBackPrize = this.backPrizes.map((item, index) => ({
        order: index + 1,
        value: +item,
        type: 'back',
      }))
      const formattedMainPrize = this.mainPrizes.map((item, index) => ({
        order: index + 1,
        value: +item,
        type: 'main',
      }))
      if (this.selectedPrizeFrom === 'manual' && !formattedMainPrize.length) {
        return this.showToastError('Please enter main prizes', 'Error')
      }

      const listPrizes = [...formattedFrontPrize, ...formattedBackPrize, ...formattedMainPrize]
      const formatListFlexi = this.optionsFlexi.map(i => Number(i))

      const data = {
        name: this.compName,
        ...(this.guestName && { guestName: this.guestName }),
        moreInfo: this.moreInfo,
        openForEntry: this.formatDateSend(this.openDate),
        competitionDate: this.formatDateSend(this.competitionDate),
        endDate: this.formatDateSend(this.endDate),
        participantDescriptions: this.participantsDes,
        remember: this.remember,
        scratchings: this.scratchings,
        entries,
        price: this.priceTo,
        latePrice: this.latePrice,
        morningLockout: this.formatDateSend(this.morningLockout),
        afternoonLockout: this.formatDateSend(this.afternoonLockout),
        // bannerUrl: this.imageWebUrl,
        // bannerMobileUrl: this.imageMobileUrl,
        prizeMode: this.selectedPrizeFrom,
        ...(formatListFlexi.length > 0 && { flexiValues: formatListFlexi }),
        useFlexiRollOverWallet: this.selectedFlexiRollOverPool ? 1 : 0,
        apiPrice: Number(this.apiPrice),
        ...(this.selectedFlexiRollOverPool && { flexiRollOverAmount: Number(this.flexiRollOverAmount) }),
        joinTimesLimit: Number(this.limitBetslip),
        ...(this.manualBetSlipEntry && { manualBetSlipEntry: Number(this.manualBetSlipEntry) }),
        prizeType: this.selectedTransferPrize,
        lockoutLeg: this.formatDateSend(this.morningLockout),
        isJackpotEnabled: this.selectedJackpot,
        status: 'draft'
      }

      const res = await this.createCompAsync({
        prizes: listPrizes,
        data,
      })
      if (res) {
        this.showToastSuccess('Success', `Create competition successfully!`)
        this.$router.push({ name: 'comp-list' })
      }
    },
    formatNumberBetslip(value) {
      let val = value.replace(/[^0-9.]/g, '')
      if (!Number.isInteger(Number(val))) {
        val = ''
      }
      const parts = val.split('.')
      parts[0] = parts[0].replace(/^0+/, '')
      val = parts.join('.')
      const decimalRegex = /^\d*\.?\d*$/
      if (!decimalRegex.test(val)) {
        val = ''
      }
      const numericVal = parseFloat(val)
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(numericVal)) {
        val = ''
      } else if (numericVal < 1) {
        val = '1'
      }
      return val
    },

    formatNumber(value) {
      const regexNumber = /^[0-9.]+$/

      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return value.slice(0, value.length - 1)
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },
    formatNumberFlexi(value) {
      const regexNumber = /^[0-9.]+$/

      if (Number(value) >= 100) return ''
      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return value.slice(0, value.length - 1)
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },
    // async inputImageRenderer(evt) {
    //   const formData = new FormData()
    //   const file = evt.target.files[0]
    //   if (file && file.size <= 10 * 1024 * 1024) {
    //     this.fileImage = file

    //     useInputImageRenderer(evt.target, base64 => {
    //       this.webImage = base64
    //     })
    //     formData.append('image', file)
    //     await this.uploadImage(formData)
    //   } else {
    //     this.showToastError('Error', 'The file is less than or equal to 10MB')
    //   }
    // },
    // removeImageRenderer() {
    //   this.webImage = null
    // },
    // async inputMobileImageRenderer(evt) {
    //   const formData = new FormData()
    //   const file = evt.target.files[0]
    //   if (file && file.size <= 10 * 1024 * 1024) {
    //     this.fileMobileImage = file

    //     useInputImageRenderer(evt.target, base64 => {
    //       this.mobileImage = base64
    //     })
    //     formData.append('image', file)
    //     await this.uploadImageMobile(formData)
    //   } else {
    //     this.showToastError('Error', 'The file is less than or equal to 10MB')
    //   }
    // },
    // removeMobileImageRenderer() {
    //   this.mobileImage = null
    // },
    handleCreateLeg() {
      this.frontEntryList = this.frontEntryList.map(item => {
        const checkItem = this.listEventPicked.find(i => i.type === item.legId)
        if (checkItem)
          return {
            ...item,
            ...checkItem,
            entryType: 0,
          }
        return {
          legId: item.legId,
          title: item.title,
          value: '',
        }
      })
      this.backEntryList = this.backEntryList.map(item => {
        const checkItem = this.listEventPicked.find(i => i.type === item.legId + 7)
        if (checkItem)
          return {
            ...item,
            ...checkItem,
            entryType: 1,
          }
        return {
          legId: item.legId,
          title: item.title,
          value: '',
        }
      })
    },
    handleRemoveLeg() {
      this.setPickListEvent([...this.frontEntryList, ...this.backEntryList])
    },
    handleUpdateFrontPrizes(data) {
      this.frontPrizes = data
    },
    handleUpdateBackPrizes(data) {
      this.backPrizes = data
    },
    handleAddMoreOptionFlexi() {
      this.optionsFlexi.push('')
    },
    handleRemoveOptionFlexi() {
      this.optionsFlexi.pop()
    },
    formatPriceRollOver(value) {
      if (value < 0) return null
      if (value > this.flexiRollOverWallet) return this.flexiRollOverWallet
      return value
    },
    onClickInput() {
      const fileInput = this.$refs.fileInput
      fileInput.click()
    },
    handleFileUpload(event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = () => {
        this.parseCSV(reader.result)
      }
      reader.readAsText(file)
      this._resetInputFile()
    },
    parseCSV(csvData) {
      parse(csvData, { delimiter: ',' }, (err, data) => {
        if (err) {
          console.error(err)
          return
        }
        data.shift()
        const newList = data.map(i => i[1])
        this.mainPrizes = newList
      })
    },
    handleRemoveAllPrize() {
      this.mainPrizes = []
    },
    handleShowReviewList() {
      this.$refs['list-review'].show()
    },
    _resetInputFile() {
      this.$refs.fileInput.value = null
    },
  },
  setup(_, { emit }) {
    const { showToastSuccess, showToastError } = useToastHandler()
    const { refFormObserver, getValidationState, resetForm } = formValidation(() => { })

    const onSubmit = () => {
      emit('next-step')
    }

    return {
      showToastSuccess,
      showToastError,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      limitCharacters,
      required,
      integer,
      convertTZ,
      formatISO,
      formatDate,
      getObjectChange,
      formatDateUTC,
      formatDateSend,
      formatDateTimeLocal,
      formatCurrencyBuiltInFunc,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.text-modal {
  color: black !important;
}

.banner-img {
  max-width: 100%;
}
</style>
