var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"pick-prize",attrs:{"title":_vm.typePick === 'front' ? 'Front Prizes' : 'Back Prizes',"centered":"","cancel-variant":"outline-secondary","size":"xl"},on:{"hidden":_vm.resetModal,"ok":_vm.handleSubmitModalPrize},scopedSlots:_vm._u([{key:"modal-ok",fn:function(){return [(_vm.loadingAddEntry)?_c('Spinner'):_c('span',[_vm._v("Save")])]},proxy:true}])},[_c('validation-observer',{ref:"simpleRules"},[_c('b-row',{staticClass:"text-modal"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0 text-modal"},[_vm._v(" "+_vm._s(("" + (_vm.typePick === 'front' ? '10 Front Prizes' : '10 Back Prizes')))+" ")]),_c('b-row',{staticClass:"ml-1 mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"1st","label-for":"1st-prize"}},[_c('validation-provider',{attrs:{"name":"1st prize","vid":"1st-prize","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"1stPrize","type":"number"},model:{value:(_vm.prize1),callback:function ($$v) {_vm.prize1=$$v},expression:"prize1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"2nd","label-for":"2nd-prize"}},[_c('validation-provider',{attrs:{"name":"2nd prize","vid":"2nd-prize","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"2ndPrize","type":"number"},model:{value:(_vm.prize2),callback:function ($$v) {_vm.prize2=$$v},expression:"prize2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"3rd","label-for":"3rd-prize"}},[_c('validation-provider',{attrs:{"name":"3rd prize","vid":"3rd-prize","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"3rdPrize","type":"number"},model:{value:(_vm.prize3),callback:function ($$v) {_vm.prize3=$$v},expression:"prize3"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"4th","label-for":"4th-prize"}},[_c('validation-provider',{attrs:{"name":"4th prize","vid":"4th-prize","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"4thPrize","type":"number"},model:{value:(_vm.prize4),callback:function ($$v) {_vm.prize4=$$v},expression:"prize4"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"5th","label-for":"5th-prize"}},[_c('validation-provider',{attrs:{"name":"5th prize","vid":"5th-prize","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"5thPrize","type":"number"},model:{value:(_vm.prize5),callback:function ($$v) {_vm.prize5=$$v},expression:"prize5"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"6th","label-for":"6th-prize"}},[_c('validation-provider',{attrs:{"name":"6th prize","vid":"6th-prize","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"6thPrize","type":"number"},model:{value:(_vm.prize6),callback:function ($$v) {_vm.prize6=$$v},expression:"prize6"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"7th","label-for":"7th-prize"}},[_c('validation-provider',{attrs:{"name":"7th prize","vid":"7th-prize","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"7thPrize","type":"number"},model:{value:(_vm.prize7),callback:function ($$v) {_vm.prize7=$$v},expression:"prize7"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"8th","label-for":"8th-prize"}},[_c('validation-provider',{attrs:{"name":"8th prize","vid":"8th-prize","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"8thPrize","type":"number"},model:{value:(_vm.prize8),callback:function ($$v) {_vm.prize8=$$v},expression:"prize8"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"9th","label-for":"9th-prize"}},[_c('validation-provider',{attrs:{"name":"9th prize","vid":"9th-prize","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"9thPrize","type":"number"},model:{value:(_vm.prize9),callback:function ($$v) {_vm.prize9=$$v},expression:"prize9"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"10th","label-for":"10th-prize"}},[_c('validation-provider',{attrs:{"name":"10th prize","vid":"10th-prize","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"10thPrize","type":"number"},model:{value:(_vm.prize10),callback:function ($$v) {_vm.prize10=$$v},expression:"prize10"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }