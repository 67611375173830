<template>
  <b-modal
    ref="pick-prize"
    :title="typePick === 'front' ? 'Front Prizes' : 'Back Prizes'"
    centered
    cancel-variant="outline-secondary"
    size="xl"
    @hidden="resetModal"
    @ok="handleSubmitModalPrize"
  >
    <validation-observer ref="simpleRules">
      <b-row class="text-modal">
        <b-col cols="12">
          <h5 class="mb-0 text-modal">
            {{ `${typePick === 'front' ? '10 Front Prizes' : '10 Back Prizes'}` }}
          </h5>
          <b-row class="ml-1 mt-1">
            <!-- 1st -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="1st"
                label-for="1st-prize"
              >
                <validation-provider
                  #default="{ errors }"
                  name="1st prize"
                  vid="1st-prize"
                  rules="required"
                >
                  <b-form-input
                    id="1stPrize"
                    v-model="prize1"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- 2nd -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="2nd"
                label-for="2nd-prize"
              >
                <validation-provider
                  #default="{ errors }"
                  name="2nd prize"
                  vid="2nd-prize"
                  rules="required"
                >
                  <b-form-input
                    id="2ndPrize"
                    v-model="prize2"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- 3rd -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="3rd"
                label-for="3rd-prize"
              >
                <validation-provider
                  #default="{ errors }"
                  name="3rd prize"
                  vid="3rd-prize"
                  rules="required"
                >
                  <b-form-input
                    id="3rdPrize"
                    v-model="prize3"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- 4th -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="4th"
                label-for="4th-prize"
              >
                <validation-provider
                  #default="{ errors }"
                  name="4th prize"
                  vid="4th-prize"
                  rules="required"
                >
                  <b-form-input
                    id="4thPrize"
                    v-model="prize4"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- 5th -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="5th"
                label-for="5th-prize"
              >
                <validation-provider
                  #default="{ errors }"
                  name="5th prize"
                  vid="5th-prize"
                  rules="required"
                >
                  <b-form-input
                    id="5thPrize"
                    v-model="prize5"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- 6th -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="6th"
                label-for="6th-prize"
              >
                <validation-provider
                  #default="{ errors }"
                  name="6th prize"
                  vid="6th-prize"
                  rules="required"
                >
                  <b-form-input
                    id="6thPrize"
                    v-model="prize6"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- 7th -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="7th"
                label-for="7th-prize"
              >
                <validation-provider
                  #default="{ errors }"
                  name="7th prize"
                  vid="7th-prize"
                  rules="required"
                >
                  <b-form-input
                    id="7thPrize"
                    v-model="prize7"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- 8th -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="8th"
                label-for="8th-prize"
              >
                <validation-provider
                  #default="{ errors }"
                  name="8th prize"
                  vid="8th-prize"
                  rules="required"
                >
                  <b-form-input
                    id="8thPrize"
                    v-model="prize8"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- 9th -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="9th"
                label-for="9th-prize"
              >
                <validation-provider
                  #default="{ errors }"
                  name="9th prize"
                  vid="9th-prize"
                  rules="required"
                >
                  <b-form-input
                    id="9thPrize"
                    v-model="prize9"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- 10th -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="10th"
                label-for="10th-prize"
              >
                <validation-provider
                  #default="{ errors }"
                  name="10th prize"
                  vid="10th-prize"
                  rules="required"
                >
                  <b-form-input
                    id="10thPrize"
                    v-model="prize10"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-ok>
      <Spinner v-if="loadingAddEntry" />
      <span v-else>Save</span>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  BRow, BCol, VBModal, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import { arrayProp, booleanProp, stringProp } from '@/helpers/props'
import useToastHandler from '@/services/toastHandler'
import Spinner from '@core/components/Spinner'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    Spinner,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    typePick: stringProp(),
    data: arrayProp(),
    isEdit: booleanProp(),
  },

  data() {
    return {
      required,
      prize1: this.data ? this.data[0] : null,
      prize2: this.data ? this.data[1] : null,
      prize3: this.data ? this.data[2] : null,
      prize4: this.data ? this.data[3] : null,
      prize5: this.data ? this.data[4] : null,
      prize6: this.data ? this.data[5] : null,
      prize7: this.data ? this.data[6] : null,
      prize8: this.data ? this.data[7] : null,
      prize9: this.data ? this.data[8] : null,
      prize10: this.data ? this.data[9] : null,
    }
  },
  computed: {
    ...mapState('races/updateComp', ['loadingAddEntry']),

  },
  methods: {
    ...mapActions({
      setListFormatFrontPrizes: 'races/createRace/setListFormatFrontPrizes',
      setDataFrontPrizes: 'races/createRace/setDataFrontPrizes',
      setListFormatBackPrizes: 'races/createRace/setListFormatBackPrizes',
      setDataBackPrizes: 'races/createRace/setDataBackPrizes',
      updatePrize: 'races/updateComp/addPrize',
      getCompDetail: 'races/compDetail/getCompDetail',
    }),
    openModal() {
      if (!this.isEdit) {
        this.resetModal()
      }
      if (this.data) {
        this.prize1 = this.data[0] || null
        this.prize2 = this.data[1] || null
        this.prize3 = this.data[2] || null
        this.prize4 = this.data[3] || null
        this.prize5 = this.data[4] || null
        this.prize6 = this.data[5] || null
        this.prize7 = this.data[6] || null
        this.prize8 = this.data[7] || null
        this.prize9 = this.data[8] || null
        this.prize10 = this.data[9] || null
      }
      this.$refs['pick-prize'].show()
    },
    converArr(item, length) {
      return Array.from({ length }, () => item)
    },
    resetModal() {
      this.prize1 = null
      this.prize2 = null
      this.prize3 = null
      this.prize4 = null
      this.prize5 = null
      this.prize6 = null
      this.prize7 = null
      this.prize8 = null
      this.prize9 = null
      this.prize10 = null
    },
    async handleSubmitModalPrize(event) {
      event.preventDefault()
      const valid = await this.$refs.simpleRules.validate()
      if (!valid) return

      if (this.$route.name === 'comp-update') {
        const listUpdate = []
        for (let i = 0; i < 10; i++) {
          if (this.data[i] !== this[`prize${i + 1}`]) {
            listUpdate.push({
              order: i + 1,
              value: Number(this[`prize${i + 1}`]),
              type: this.typePick,
            })
          }
        }

        if (listUpdate.length > 0) {
          const res = await this.updatePrize({
            competitionId: this.$route.params.id,
            data: {
              prizes: listUpdate,
            },
          })
          if (res) {
            this.showToastSuccess('Success', `Update ${this.typePick} prize successfully!`)
            this.getCompDetail(this.$route.params.id)
          }
          this.$refs['pick-prize'].hide()
        }
        return
      }

      const data = [
        this.prize1,
        this.prize2,
        this.prize3,
        this.prize4,
        this.prize5,
        this.prize6,
        this.prize7,
        this.prize8,
        this.prize9,
        this.prize10,
      ]
      if (this.typePick === 'front') {
        this.$emit('on-update-front-prize', data)
      } else {
        this.$emit('on-update-back-prize', data)
      }
      this.$refs['pick-prize'].hide()
    },
  },

  setup(_, { emit }) {
    const { refFormObserver, getValidationState, resetForm } = formValidation(() => {})
    const { showToastSuccess } = useToastHandler()

    const onSubmit = () => {
      const data = [
        this.prize1,
        this.prize2,
        this.prize3,
        this.prize4,
        this.prize5,
        this.prize6,
        this.prize7,
        this.prize8,
        this.prize9,
        this.prize10,
      ]
      emit('next-step')
      if (this.typePick === 'front') {
        emit('on-update-front-prize', data)
      }
      if (this.typePick === 'back') {
        emit('on-update-back-prize', data)
      }
    }

    return {
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      showToastSuccess,
    }
  },
}
</script>

<style lang="scss" scoped>
.add-btn {
  padding: 2px 4px;
}

.remove-btn {
  padding: 0px 4px 2px 4px;
  margin-left: 1px;
}

.text-modal {
  color: black;
}

.modal.modal-footer {
  display: none !important;
}
</style>
