import {
  ref, computed, watch,
} from '@vue/composition-api'
import { debounce } from 'debounce'

export default function useGetListRacing() {
  const columns = [
    {
      thStyle: { verticalAlign: 'middle' },
      key: 'event',
      sortable: true,
    },
    {
      thStyle: { verticalAlign: 'middle' },
      key: 'raceNumber',
      label: 'Race number',
      sortable: true,
    },
    {
      thStyle: { verticalAlign: 'middle' },
      key: 'id',
      sortable: true,
    },
    {
      thStyle: { verticalAlign: 'middle' },
      key: 'raceName',
      label: 'Race name',
      sortable: true,
    },
    {
      thStyle: { verticalAlign: 'middle' },
      key: 'date',
      sortable: true,
    },
    {
      thStyle: { verticalAlign: 'middle' },
      key: 'time',
      label: 'START TIME',
    },
    {
      thStyle: { verticalAlign: 'middle' },
      key: 'runners',
      label: 'no. of runners',
    },
    {
      thStyle: { verticalAlign: 'middle' },
      key: 'choose',
      label: 'choose leg',
    },
    {
      thStyle: { verticalAlign: 'middle' },
      key: 'status',
    },
  ]
  const refRacingListTable = ref(null)
  const statusOptions = ['All', 'Live', 'Coming Soon', 'Expired']
  const perPageOptions = [15, 30]

  const statusFilter = ref('All')
  const searchQuery = ref('')
  const perPage = ref(15)
  const currentPage = ref(1)
  const totalRaces = ref(0)

  // const filterResult = computed(() => store.getters['racing/filterRacing']({ name: searchQuery.value, status: statusFilter.value }))

  const updateFilter = val => {
    statusFilter.value = val
  }
  // onMounted(() => {
  //   store.dispatch('racing/getRacingList')
  // })

  const dataMeta = computed(() => {
    const localItemsCount = refRacingListTable.value ? refRacingListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRaces.value,
    }
  })

  const refetchData = () => {
    refRacingListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  watch(
    [searchQuery],
    debounce(() => {
      totalRaces.value = 0
      refetchData()
      // eslint-disable-next-line comma-dangle
    }, 700)
  )

  return {
    columns,
    statusOptions,
    perPageOptions,
    // state
    statusFilter,
    searchQuery,
    perPage,
    currentPage,
    totalRaces,

    // function
    updateFilter,
    // filterResult,
    dataMeta,
    refRacingListTable,
  }
}
