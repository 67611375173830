<template>
  <b-row class="mb-1 d-flex align-items-center">
    <b-col
      cols="3"
      class="font-weight-bold"
    >
      <span>{{ title }}</span>
    </b-col>
    <b-col cols="7">
      <div
        class="w-full d-flex align-items-center"
        style="min-height: 38px; padding: 6px 14px; border: 1px solid; border-radius: 6px; white-space: wrap"
      >
        {{ value }}
      </div>
    </b-col>
    <b-col
      cols="2"
      class="d-flex"
    >
      <b-button
        v-if="!value"
        variant="primary"
        class="mb-1 mb-sm-0 add-btn"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        :disabled="$route.name === 'comp-update' && new Date() >= new Date(dataDetail.openForEntry) && dataDetail.status !== 'draft'"
        @click="handleShowModal"
      >
        <template> + </template>
      </b-button>
      <b-button
        v-else
        variant="primary"
        class="mb-1 mb-sm-0 remove-btn"
        :disabled="$route.name === 'comp-update' && new Date() >= new Date(dataDetail.openForEntry) && dataDetail.status !== 'draft'"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="handleRemoveLeg"
      >
        <template> - </template>
      </b-button>
      <div
        class="ml-75 delete-btn"
        @click="handleDeleteLeg"
      >
        x
      </div>
    </b-col>
    <b-modal
      ref="pick-leg"
      hide-footer
      title="Choose leg"
      centered
      cancel-variant="outline-secondary"
      size="xl"
      @hide="handleHide"
    >
      <b-row class="text-modal">
        <b-col cols="12">
          <RacingList
            :leg-number="legNumber"
            :type="type"
            @hide-modal="handleHideModal"
            @create-leg="handleCreateLeg"
          />
        </b-col>
      </b-row>
    </b-modal>
  </b-row>
</template>

<script>
import useToastHandler from '@/services/toastHandler'
import { mapState, mapActions } from 'vuex'
import { stringProp, numberProp } from '@/helpers/props'
import {
  BCol, BRow, BButton, VBModal,
} from 'bootstrap-vue'
import RacingList from '../racing-list/RacingList.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    RacingList,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    title: stringProp(),
    value: stringProp(),
    legNumber: numberProp(),
    type: stringProp(),
  },

  data() {
    return {
      loading: false,
      createLeg: false,
    }
  },
  computed: {
    ...mapState('races/listRace', ['eventPicked', 'listEventPicked', 'racePicked']),
    ...mapState('races/updateComp', ['addEntryId']),
    ...mapState('races/compDetail', ['dataDetail']),
  },

  methods: {
    ...mapActions({
      setPickEvent: 'races/listRace/setPickEvent',
      setPickListEvent: 'races/listRace/setPickListEvent',
      setPickFrontBack: 'races/listRace/setPickFrontBack',
      setPickFrontBackNumber: 'races/listRace/setPickFrontBackNumber',
    }),
    async handleShowModal() {
      this.$refs['pick-leg'].show()
      const type = this.type === 'front' ? `${this.legNumber} (FRONT)` : `${this.legNumber} (BACK)`
      const number = this.type === 'front' ? 0 : 1
      this.setPickFrontBack(type)
      this.setPickFrontBackNumber(number)
    },
    handleHideModal() {
      this.$refs['pick-leg'].hide()
      const data = {
        type: 'add',
        event: this.eventPicked,
        legNumber: this.legNumber,
        raceName: this.racePicked?.track?.name,
        meetingId: this.racePicked?.id,
      }
      this.$emit('update-data', data)
      if (this.addEntryId) {
        this.showToastSuccess(
          'Success',
          `You are adding Leg ${this.legNumber} for ${this.type === 'front' ? 'Morning lockout' : 'Afternoon lockout'}`,
        )
      }
    },
    handleRemoveLeg() {
      const data = {
        type: 'remove',
        name: this.value,
        legNumber: this.legNumber,
        raceName: this.racePicked?.track?.name,
        meetingId: this.racePicked?.id,
      }
      const newListPicked = this.listEventPicked.map(item => {
        if (item.value === this.value) {
          return {
            legId: item.legId,
            title: item.title,
            value: '',
          }
        }
        return item
      })
      this.setPickListEvent(newListPicked)

      this.$emit('update-data', data)
    },
    handleDeleteLeg() {
      if (this.$route.name === 'comp-update' && new Date() >= new Date(this.dataDetail.openForEntry)) return
      const data = {
        type: this.type,
        legNumber: this.legNumber,
        value: this.value,
      }
      this.$emit('delete-data', data)
    },
    handleCreateLeg() {
      this.createLeg = true
      this.$emit('create-leg')
      this.$refs['pick-leg'].hide()
      this.createLeg = false
    },
    handleHide() {
      if (this.$route.name === 'comp-update') return
      if (!this.createLeg) {
        this.$emit('remove-leg')
      }
    },
  },
  setup() {
    const { showToastSuccess, showToastError } = useToastHandler()

    return {
      showToastSuccess,
      showToastError,
    }
  },
}
</script>

<style lang="scss" scoped>
.add-btn {
  padding: 2px 4px;
}

.remove-btn {
  padding: 0px 4px 2px 4px;
  margin-left: 1px;
}

.delete-btn {
  color: red;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.text-modal {
  color: black;
}

.modal.modal-footer {
  display: none !important;
}
</style>
