<!-- eslint-disable vue/no-template-shadow -->
<template>
  <section>
    <b-card v-if="!eventId">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            lg="4"
            class="d-flex align-items-center mt-1"
            style="flex-direction: column;"
          >
            <label class="mr-1">Filter</label>
            <b-form-datepicker
              id="datepicker"
              v-model="time"
              class="mx-2"
              locale="en-US"
              :date-format-options="{ month: '2-digit', day: '2-digit', year: 'numeric' }"
              placeholder="Pick a day"
              :date-disabled-fn="dateDisabled"
            />
          </b-col>
          <b-col
            cols="12"
            lg="4"
            class="d-flex align-items-center mt-1"
            style="flex-direction: column;"
          >
            <label>Start time</label>
            <v-select
              v-model="sortOrderStartTime"
              style="width: 150px;"
              :options="orderOptions"
            />
          </b-col>
          <b-col
            cols="12"
            lg="4"
            class="d-flex align-items-center mt-1"
            style="flex-direction: column;"
          >
            <label>NO. Of Runners</label>
            <v-select
              v-model="sortOrderRunners"
              style="width: 150px;"
              :options="orderOptions"
            />
          </b-col>

        </b-row>
        <b-row>
          <b-col class="d-flex justify-content-end mr-4 mt-4">
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="handleCreateLeg"
            >

              <template> Create leg </template>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refRacingListTable"
        :items="listRace"
        :fields="columns"
        responsive
        primary-key="racing"
        show-empty
        :no-sort-reset="true"
      >
        <template #empty>
          <div
            v-if="$apollo.queries.meetingsDated.loading"
            class="text-center my-2"
          >
            <p>Loading... Please wait</p>
          </div>

          <div
            v-else-if="!$apollo.queries.meetingsDated.loading && meetingsDated && meetingsDated.length === 0"
            class="text-center my-2"
          >
            No matching records found
          </div>
        </template>
        <!-- event -->
        <template #cell(event)="{ item }">
          <b-media vertical-align="center">
            <div
              class="font-weight-bold d-block text-nowrap text-body cursor-pointer"
            >
              {{ item.meetingName }}
            </div>
          </b-media>
        </template>
        <!-- raceNumber -->
        <template #cell(raceNumber)="{ item }">
          <b-media vertical-align="center">
            <div
              class="font-weight-bold d-block text-nowrap text-body cursor-pointer"
            >
              {{ item.number }}
            </div>
          </b-media>
        </template>
        <!-- raceName -->
        <template #cell(raceName)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ item.name }}
            </div>
          </b-media>
        </template>
        <!-- raceName -->
        <template #cell(date)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ formatTime(item.startTime,'dd-MM-yyyy' ) }}
            </div>
          </b-media>
        </template>
        <!-- time -->
        <template #cell(time)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ formatTime(item.startTime) }}
            </div>
          </b-media>
        </template>
        <!-- runners -->
        <template #cell(runners)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ item.competitors.length }}
            </div>
          </b-media>
        </template>
        <!-- choose -->
        <template #cell(choose)="{ item }">
          <b-dropdown
            :id="item.id"
            variant="link"
            no-caret
            boundary="viewport"
            :disabled="($route.name === 'comp-update' && showLeg(item) !== '') || item.status === 'ABANDONED' || item.status === 'INTERIM'"
          >
            <template #button-content>
              <div
                v-if="showLeg(item)"
                style="font-weight: 900; display: flex; gap: 4px; align-items: center"
                @click="handleDelete(item)"
              >
                <span>Leg {{ showLeg(item).type }}</span>
                <span style="padding: 2px; border: 1px solid grey; color: red;">x</span>
              </div>
              <div
                v-else
                style="cursor: pointer"
              >
                <feather-icon
                  icon="EditIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
              </div>
            </template>
            <template>
              <b-dropdown-item
                v-for="i in legList"
                :key="i.number"
                :disabled="disabledLeg(i)"
                style="text-align: center;"
                @click="handleSelectLeg(item, i)"
              >
                {{ i.value }}

              </b-dropdown-item>
            </template>

          </b-dropdown>
        </template>
        <!-- status -->
        <template #cell(status)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ item.status }}
            </div>
          </b-media>
        </template>
        >
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRaces"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </section>
</template>

<script>
import {
  BTable,
  BMedia,
  BCol,
  BRow,
  // BFormInput,
  BCard,
  // BLink,
  BPagination,
  BFormDatepicker,
  BDropdown,
  BDropdownItem,
  BButton,
  // BFormCheckbox,
  // BFormGroup,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import gql from 'graphql-tag'
// import vSelect from 'vue-select'

import { mapActions, mapState } from 'vuex'
// import RacingFilter from './RacingFilter.vue'
import {
  formatDate, formatDateTimeAustralia, formatDateQuery, formatTime, formatDateTimeLocal,
} from '@/helpers/utils'
import { subDays, addDays } from 'date-fns'
import { numberProp, stringProp } from '@/helpers/props.js'
import vSelect from 'vue-select'
import useToastHandler from '@/services/toastHandler'
import useGetListRacing from './useGetListRacing.js'

export const GET_RACE = gql`
  query ($date: Time!) {
    meetingsDated(date: $date) {
      id
      type
      track {
        name
        country
        abbreviation
        abbreviationFourChar
        location
        state
        surface
        updated
        created
      }
      races {
        number
        id
        status
        name
        startTime
        created
        competitors {
          tabNo 
        }
      }
      updated
      date
      firstRaceTime
      tabIndicator
      created
    }
  }
`

export default {
  components: {
    BTable,
    BMedia,
    BCol,
    BRow,
    BCard,
    BPagination,
    BFormDatepicker,
    BDropdown,
    // BFormCheckbox,
    // BFormGroup,
    BDropdownItem,
    BButton,
    vSelect,
  },
  props: {
    legNumber: numberProp(),
    type: stringProp(),
  },

  data() {
    return {
      eventId: null,
      eventItem: null,
      eventDetailId: null,
      eventDetailItem: null,
      meetingsDated: [],
      listRace: [],
      formatDateTimeAustralia,
      formatDate,
      time: '',
      legList: [
        { number: 1, value: 'Leg 1' },
        { number: 2, value: 'Leg 2' },
        { number: 3, value: 'Leg 3' },
        { number: 4, value: 'Leg 4' },
        { number: 5, value: 'Leg 5' },
        { number: 6, value: 'Leg 6' },
        { number: 7, value: 'Leg 7' },
        { number: 8, value: 'Leg 8' },
        { number: 9, value: 'Leg 9' },
        { number: 10, value: 'Leg 10' },
        { number: 11, value: 'Leg 11' },
        { number: 12, value: 'Leg 12' },
        { number: 13, value: 'Leg 13' },
        { number: 14, value: 'Leg 14' },
      ],
      selectedLegList: null,
      orderOptions: [
        { label: 'ASC', value: 'ASC' },
        { label: 'DESC', value: 'DESC' },
      ],
      sortOrderStartTime: '',
      sortOrderRunners: '',
    }
  },
  computed: {
    ...mapState('races/listRace', ['tokenBetMaket', 'expireAt']),
    ...mapState('races/listRace', ['listEventPicked', 'racePicked', 'pickFrontBack', 'pickFrontBackNumber']),
    converTime() {
      // if (this.time === formatDate(new Date())) return new Date().toISOString()
      return new Date(this.time).toISOString()
    },
  },
  watch: {
    meetingsDated() {
      this.fetchRaces()
    },
    sortOrderStartTime(val) {
      if (val) this.sortOrderRunners = ''
      this.handleSortList()
    },
    sortOrderRunners(val) {
      if (val) this.sortOrderStartTime = ''
      this.handleSortList()
    },
    time(val) {
      if (val) this.sortOrderRunners = ''
      if (val) this.sortOrderStartTime = ''
    },
  },
  emits: ['updateStatusFilter'],
  async created() {
    this.time = formatDate(new Date())
    if (new Date().getTime() / 1000 >= this.expireAt) {
      await this.getTokenBetMaker()
    }
    this.$apollo.addSmartQuery('meetingsDated', {
      query: GET_RACE,
      context: () => ({
        headers: {
          authorization: this.tokenBetMaket,
        },
      }),
      variables() {
        return {
          date: this.converTime,
        }
      },
    })
  },
  methods: {
    ...mapActions({
      getTokenBetMaker: 'races/listRace/getTokenBetMaker',
      setPickRace: 'races/listRace/setPickRace',
      setPickEvent: 'races/listRace/setPickEvent',
      setPickListEvent: 'races/listRace/setPickListEvent',
      addEntry: 'races/updateComp/addEntry',
      getCompDetail: 'races/compDetail/getCompDetail',
    }),
    onChangeFilter(selected) {
      this.$emit('updateStatusFilter', selected)
    },
    fetchRaces() {
      const data = this.meetingsDated
      const formattedList = []

      data.forEach(item => {
        const meetingName = item.track.name
        const { races, id } = item

        races.forEach(nestedItem => {
          if (nestedItem.status === 'FINAL') return
          formattedList.push({ ...nestedItem, meetingName, meetingId: id })
        })
      })
      if (!this.sortOrderRunners && !this.sortOrderStartTime) {
        this.listRace = formattedList
        return formattedList
      }
    },
    handleSortList() {
      if (this.sortOrderRunners && this.sortOrderRunners.value) {
        const val = this.sortOrderRunners.value
        if (val === 'DESC') return this.listRace = this.listRace.sort((a, b) => b.competitors.length - a.competitors.length)
        if (val === 'ASC') return this.listRace = this.listRace.sort((a, b) => a.competitors.length - b.competitors.length)
      }
      if (this.sortOrderStartTime && this.sortOrderStartTime.value) {
        const val = this.sortOrderStartTime.value
        if (val === 'DESC') return this.listRace = this.listRace.sort((a, b) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime())
        if (val === 'ASC') return this.listRace = this.listRace.sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime())
      }
    },
    dateDisabled(ymd, date) {
      // Return `true` if the date should be disabled
      return date < subDays(new Date(), 1) || date > addDays(new Date(), 3)
    },
    disabledLeg(item) {
      // eslint-disable-next-line no-shadow
      const itm = this.listEventPicked.find((i, index) => index + 1 === item.number)
      if (itm && itm.id) return true
      return false
    },
    showLeg(item) {
      const itm = this.listEventPicked.find(i => i && i.id === item.id)
      if (itm && itm.id) return itm
      return ''
    },
    async handleSelectLeg(item, i) {
      if (this.$route.name === 'comp-update') {
        const res = await this.addEntry({
          competitionId: this.$route.params.id,
          raceId: item.id,
          raceName: item.name,
          startTime: item.startTime,
          entryType: i.number > 7 ? 1 : 0,
          meetingId: item.meetingId,
          meetingName: item.meetingName,
          raceNumber: item.number,
          orderNumber: i.number,
        })

        if (res) {
          this.showToastSuccess('Success', 'Update leg successfully!')
        }
        const id = i.number > 7 ? i.number - 7 : i.number
        const data = {
          ...item,
          raceName: `${item.meetingName} R${item.number}, ${formatDateTimeLocal(item.startTime)}`,
          value: `${item.meetingName} R${item.number}, ${formatDateTimeLocal(item.startTime)}`,
          type: i.number,
          legId: id,
          title: `LEG ${id}`,
          entryType: i.number > 7 ? 1 : 0,
          ...(this.$route.name === 'comp-update' && { entryId: res.entryId }),
        }

        this.setPickEvent(data)
        const newList = [...this.listEventPicked]
        const index = i.number - 1
        newList[index] = data
        this.setPickListEvent(newList)
      } else {
        const data = {
          ...item,
          raceName: `${item.meetingName} R${item.number}, ${formatDateTimeLocal(item.startTime)}`,
          value: `${item.meetingName} R${item.number}, ${formatDateTimeLocal(item.startTime)}`,
          type: i.number,
        }

        this.setPickEvent(data)
        const newList = [...this.listEventPicked]
        const index = i.number - 1
        newList[index] = data
        this.setPickListEvent(newList)
      }
    },
    handleDelete(item) {
      const newList = this.listEventPicked.map(i => {
        if (i.id === item.id) {
          const ind = i.type > 7 ? i.type - 7 : i.type
          return {
            legId: ind,
            title: `LEG ${ind}`,
            value: '',
          }
        }
        return i
      })
      this.setPickListEvent(newList)
    },
    handleCreateLeg() {
      this.$emit('create-leg')
    },
  },
  setup() {
    const { showToastSuccess } = useToastHandler()
    const {
      columns,
      statusOptions,
      perPageOptions,
      statusFilter,
      searchQuery,
      perPage,
      updateFilter,
      // filterResult,
      currentPage,
      totalRaces,
      refRacingListTable,
      dataMeta,
    } = useGetListRacing()
    return {
      columns,
      statusOptions,
      perPageOptions,
      statusFilter,
      searchQuery,
      perPage,
      updateFilter,
      // filterResult,
      currentPage,
      totalRaces,
      refRacingListTable,
      dataMeta,
      formatDateQuery,

      formatTime,
      formatDateTimeLocal,

      showToastSuccess,

    }
  },
}
</script>

<style lang="scss">
.dark-layout .dropdown-menu .dropdown-item.disabled {
    color: red !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.v-select .vs__dropdown-toggle,
.v-select .vs__search {
  cursor: pointer;
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>
